import * as z from "zod";
import { parseBool } from "@copmer/calculator-widget";
import { isSet } from "@/lib/is-set";

type TransformSchemaPrimitive =
  | {
      $$type: "enum";
      options: any[];
      default?: number;
    }
  | {
      $$type: "string";
      default?: string;
    }
  | {
      $$type: "number";
      default?: number;
    }
  | {
      $$type: "boolean";
      default?: boolean;
    }
  | {
      $$type: "array";
      items: TransformSchema | TransformSchemaPrimitive;
    };
interface TransformSchema
  extends Record<string, TransformSchemaPrimitive | TransformSchema> {}

const resolveType = (schema: z.ZodType<any, any>) => {
  while (schema instanceof z.ZodEffects || schema instanceof z.ZodOptional) {
    if (schema instanceof z.ZodEffects) {
      schema = schema._def.schema;
    } else if (schema instanceof z.ZodOptional) {
      schema = schema._def.innerType;
    }
  }

  return schema;
};

export const generateTransformSchema = (
  schema: z.ZodType<any, any>
): TransformSchema | TransformSchemaPrimitive => {
  let parser = resolveType(schema);

  if (parser instanceof z.ZodDefault) {
    return {
      ...generateTransformSchema(parser._def.innerType),
      default: parser._def.defaultValue(),
    };
  }

  if (parser instanceof z.ZodEnum) {
    return {
      $$type: "enum",
      options: parser._def.values,
    };
  }

  if (parser instanceof z.ZodNumber) {
    return {
      $$type: "number",
    };
  }

  if (parser instanceof z.ZodString) {
    return {
      $$type: "string",
    };
  }

  if (parser instanceof z.ZodBoolean) {
    return {
      $$type: "boolean",
    };
  }

  if (parser instanceof z.ZodArray) {
    let innerSchema = parser.element;

    if (innerSchema instanceof z.ZodEffects) {
      innerSchema = innerSchema._def.schema;
    }

    return {
      $$type: "array",
      items: generateTransformSchema(innerSchema),
    };
  }

  if (parser instanceof z.ZodObject) {
    const transformSchema: TransformSchema = {};

    for (const key of Object.keys((schema as z.ZodObject<any, any>).shape)) {
      transformSchema[key] = generateTransformSchema(
        parser.shape[key as keyof typeof parser.shape]
      );
    }

    return transformSchema;
  }

  throw new Error("Unknown schema type" + parser);
};

const isSingleField = (schema: TransformSchema | TransformSchemaPrimitive) => {
  const fieldType = schema.$$type;

  return (
    fieldType === "string" ||
    fieldType === "number" ||
    fieldType === "boolean" ||
    fieldType === "enum"
  );
};

const safeParseFloat = (data: any) => {
  if (!isSet(data)) {
    return undefined;
  }

  const parsed = parseFloat(data);

  if (isNaN(parsed)) {
    return undefined;
  }

  return parsed;
};

const transformPrimitive = (
  data: any,
  schema: TransformSchemaPrimitive
): any => {
  const fieldType = schema.$$type;

  switch (fieldType) {
    case "string":
      return isSet(data) ? data.toString() : schema.default ?? undefined;
    case "number":
      return safeParseFloat(data) ?? schema.default ?? undefined;
    case "boolean":
      return parseBool(data) ?? schema.default ?? undefined;
    case "enum":
      if (!schema.options.includes(data)) {
        return schema.default ?? undefined;
      }

      return data;
    default:
      throw new Error("Unknown primitive type");
  }
};

export const transformDataWithSchemaTransforms = (
  data: any,
  schema: TransformSchema | TransformSchemaPrimitive
) => {
  let final: any;

  // If the schema is a primitive, we just return the data
  if (isSingleField(schema)) {
    return transformPrimitive(data, schema);
  }

  // If the schema is an array, we transform each element
  if (schema.$$type === "array") {
    return data.map((element: any) =>
      transformDataWithSchemaTransforms(element, schema.items)
    );
  }

  // If the schema is an object, we transform each field
  if (typeof schema === "object") {
    final = {};

    for (const key of Object.keys(schema)) {
      final[key] = transformDataWithSchemaTransforms(data[key], schema[key]);
    }

    return final;
  }

  throw new Error("Unknown schema type: " + schema);
};
