"use client";

import { PinAdapter } from "@/ui-lib/select/pinning/pin.types";

class DBPinAdapter implements PinAdapter {
  data: Record<string, string[]> = {};

  inFlight: Record<string, Promise<any>> = {};

  get = async (context: string) => {
    if (this.data[context]) {
      return this.data[context];
    }

    if (context in this.inFlight) {
      await this.inFlight[context];

      return this.data[context];
    }

    this.inFlight[context] = this.fetchData(context);

    await this.inFlight[context];

    delete this.inFlight[context];

    return this.data[context];
  };

  fetchData = async (context: string) => {
    const response = await fetch(`/app-api/select/pinned/${context}`);
    const items = await response.json();

    this.data[context] = items;

    return items;
  };

  save = async (context: string, options: string[]) => {
    this.data[context] = options;

    await fetch(`/app-api/select/pinned/${context}`, {
      method: "POST",
      body: JSON.stringify(options),
    });
  };
}

export const DBBackedPinAdapter = new DBPinAdapter();
