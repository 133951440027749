"use client";

import {
  CalcContext,
  getAPIRoutes,
  OperationType,
  useApiMutation,
  useAdminAPI,
  useCalculatorInputOptions,
} from "@copmer/calculator-widget";
import { useRouter, useSearchParams } from "next/navigation";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useCalculatorContext } from "../../_components/context";
import MultiCalculatorForm from "./form";
import { MultiModeInput, PortInput } from "../../_components/schema";
import {
  asNumber,
  parseMode,
  parseOptimizeBase,
  parseOptimizerMode,
} from "../../_components/utils";
import CalculationResults from "../../_components/CalculationResults";
import { CalculatorCopyContextProvider } from "../../_components/copy-context";
import { CopyButton } from "../../_components/CopyButton";
import { cn } from "@/lib/utils";
import { CommodityOption } from "@/actions/products/types";
import { getBasePath } from "@/lib/base-path";
import { CFROptimizerContext } from "./cfr-context";
import { isSet } from "@/lib/is-set";

function getInitialValuesFromParams(
  params: ReturnType<typeof useSearchParams>,
  isCFROptimizer = false
): Partial<MultiModeInput> {
  //
  const hire = asNumber(params, "hire");
  const sfoPrice = asNumber(params, "sfo");
  const mgoPrice = asNumber(params, "mgo");

  let optimizerMode: MultiModeInput["optimizerMode"] = parseOptimizerMode(
    params.get("oM")
  );

  if (isCFROptimizer && !optimizerMode) {
    optimizerMode = "optimize" as const;
  }

  let optimizeBase: MultiModeInput["optimizeBase"] = parseOptimizeBase(
    params.get("oB")
  );

  if (isCFROptimizer && !optimizeBase) {
    optimizeBase = "cheapest" as const;
  }

  const mode = parseMode(params.get("mode"));

  // Parse each port and operation
  const ports = params.getAll("port");

  const portList = [];

  const toNumber = (value: string | null | undefined) => {
    if (!isSet(value)) {
      return undefined;
    }

    const val = parseFloat(value);

    if (Number.isNaN(val)) {
      return undefined;
    }

    return val;
  };

  for (const [i, port] of ports.entries()) {
    const operations: OperationType[] = params
      .getAll(`p${i}-o`)
      .map((o) =>
        o.toLowerCase() === "d" ? OperationType.D : OperationType.L
      );
    const portDA = params.get(`p${i}-da`);

    const portData: PortInput = {
      port,
      portDA: toNumber(portDA),
      operations: [],
    };

    for (const [j, operation] of operations.entries()) {
      const tag = `p${i}-${j}-`;

      const amount = params.get(`${tag}amount`);
      const commodity = params.get(`${tag}commodity`);
      const cadence = params.get(`${tag}cadence`);
      const term = params.get(`${tag}terms`);
      const draft = params.get(`${tag}draft`);

      const tolerance = params.get(`${tag}pct`);
      const commodityPrice = params.get(`${tag}fob`);
      const userCfrPrice = params.get(`${tag}cfr`);
      const stowageFactor = params.get(`${tag}stowage`);

      if (!commodity) {
        continue;
      }

      portData.operations.push({
        operation,

        amount: toNumber(amount),
        commodity: commodity,
        cadence: toNumber(cadence),
        terms: term ?? undefined,
        draft: toNumber(draft),

        commodityPrice: toNumber(commodityPrice),
        userCfrPrice: toNumber(userCfrPrice),
        tolerance: toNumber(tolerance),

        stowageFactor: toNumber(stowageFactor),

        useVesselGear: undefined,
      });
    }

    portList.push(portData);
  }

  return {
    mode,
    hire,
    sfoPrice,
    mgoPrice,
    optimizerMode,
    optimizeBase,

    ports: portList,
  };
}

const paramToField: Record<string, keyof MultiModeInput> = {
  hire: "hire",
  sfo: "sfoPrice",
  mgo: "mgoPrice",
  oM: "optimizerMode",
  oB: "optimizeBase",
};

const serializeParam = (value: any) => {
  if (typeof value === "boolean") {
    return value ? "1" : "0";
  }

  if (value === undefined) {
    return "";
  }

  return `${value}`;
};

const toSearchParams = (data: MultiModeInput, isCFROptimizer: boolean) => {
  const params = new URLSearchParams();

  if (isSet(data.mode)) {
    params.append("mode", data.mode);
  }

  if (isCFROptimizer) {
    params.append("cfr", "1");
  }

  for (const [param, field] of Object.entries(paramToField)) {
    if (isSet(data[field])) {
      params.append(param, serializeParam(data[field]));
    }
  }

  for (const [i, port] of data.ports.entries()) {
    if (!isSet(port.port)) {
      continue;
    }

    params.append(`port`, port.port);

    for (const [j, operation] of port.operations.entries()) {
      params.append(
        `p${i}-o`,
        operation.operation === OperationType.D ? "D" : "L"
      );

      if (isSet(port.portDA)) {
        params.append(`p${i}-da`, `${port.portDA}`);
      }

      const tag = `p${i}-${j}-`;

      if (isSet(operation.amount)) {
        params.append(`${tag}amount`, `${operation.amount}`);
      }

      if (isSet(operation.commodity)) {
        params.append(`${tag}commodity`, operation.commodity);
      }

      if (isSet(operation.cadence)) {
        params.append(`${tag}cadence`, `${operation.cadence}`);
      }

      if (isSet(operation.terms)) {
        params.append(`${tag}terms`, operation.terms);
      }

      if (isSet(operation.draft)) {
        params.append(`${tag}draft`, `${operation.draft}`);
      }

      if (isSet(operation.tolerance)) {
        params.append(`${tag}pct`, `${operation.tolerance}`);
      }

      if (isSet(operation.commodityPrice)) {
        params.append(`${tag}fob`, `${operation.commodityPrice}`);
      }

      if (isSet(operation.userCfrPrice)) {
        params.append(`${tag}cfr`, `${operation.userCfrPrice}`);
      }

      if (isSet(operation.stowageFactor)) {
        params.append(`${tag}stowage`, `${operation.stowageFactor}`);
      }
    }
  }

  return params;
};

const isMobileSize = () => {
  return window.innerWidth < 1024;
};

const useLogPoller = (
  apiPath: string,
  onReceived: ({
    status,
    progress,
  }: {
    status: string;
    progress: number;
  }) => void
) => {
  const activeFetcher = useRef<ReturnType<typeof setInterval> | null>(null);

  const { trigger } = useApiMutation<string[]>({
    url: apiPath,
    method: "POST",
  });

  const fetchMessages = useCallback(
    async (requestId: string) => {
      const data = await trigger({
        body: {
          requestId,
        },
      });

      if (activeFetcher.current !== null) {
        // only update messages if polling has not been stopped
        onReceived(data);
      }
    },
    [onReceived, trigger]
  );

  const stop = useCallback(() => {
    if (activeFetcher.current) {
      clearInterval(activeFetcher.current);
      activeFetcher.current = null;
    }
  }, []);

  const start = useCallback(
    (requestId: string) => {
      if (activeFetcher.current) {
        // Already polling, cancel that one first
        stop();
      }

      activeFetcher.current = setInterval(() => {
        void fetchMessages(requestId);
      }, 1000);
    },
    [stop, fetchMessages]
  );

  useEffect(() => {
    return () => {
      stop();
    };
  }, [stop]);

  return useMemo(() => ({ start, stop }), [start, stop]);
};

const useOptimizerStatus = (apiPath: string) => {
  const [status, setStatus] = useState<string | null>(null);
  const [progress, setProgress] = useState<number | null>(null);

  const setOptimizerStatus = useCallback(
    (data: { status: string; progress: number } | null) => {
      setStatus(data?.status ?? null);
      setProgress(data?.progress ?? null);
    },
    [setStatus, setProgress]
  );

  const onReceived = useCallback(
    (data: { status: string; progress: number }) => {
      setStatus(data.status);
      setProgress(data.progress);
    },
    [setOptimizerStatus]
  );

  const poller = useLogPoller(apiPath, onReceived);

  return useMemo(
    () => ({
      optimizerStatus: {
        status,
        progress,
      },
      setOptimizerStatus,
      poller,
    }),
    [status, progress, poller]
  );
};

export default function MultiFreightRatePage({
  formClassName,
  baseRoute = "/platform/freight/combo",
  extraSearchParams,
  navigateOptions,
  hasCFROptimizerPermissions,
  mobileSummaryNodeSelector = "#header-extra",
}: {
  formClassName?: string;
  baseRoute?: string;
  extraSearchParams?: Record<string, string>;
  navigateOptions?: {
    scroll?: boolean;
  };
  hasCFROptimizerPermissions?: boolean;
  mobileSummaryNodeSelector?: string;
}) {
  const apiRoutes = useMemo(() => {
    return getAPIRoutes(getBasePath() ?? "", false);
  }, []);

  const { optimizerStatus, setOptimizerStatus, poller } = useOptimizerStatus(
    apiRoutes.optimizerLogs
  );

  const searchParams = useSearchParams();

  const [isCFROptimizer, setIsCFROptimizer] = useState<boolean>(
    !!hasCFROptimizerPermissions && searchParams.get("cfr") === "1"
  );

  const cfrCTX = useMemo(() => {
    return {
      cfrOptimizerEnabled: !!hasCFROptimizerPermissions,
      isCFROptimizer,
      setIsCFROptimizer,
    };
  }, [isCFROptimizer, setIsCFROptimizer, hasCFROptimizerPermissions]);

  const onOptimizerTwoStep = useMemo(() => {
    if (!isCFROptimizer) {
      return undefined;
    }

    return async (requestId: string, pending: boolean) => {
      if (pending) {
        // Clear existing messages
        setOptimizerStatus(null);

        // start polling for log messages
        poller.start(requestId);
      } else {
        poller.stop();
      }
    };
  }, [setOptimizerStatus, poller, isCFROptimizer]);

  const contextValue = useCalculatorContext(isCFROptimizer, onOptimizerTwoStep);

  const router = useRouter();

  const {
    isApiLoaded: coreApiLoaded,

    terms,
    ports,
    vesselMap,
  } = useCalculatorInputOptions(contextValue.apiRoutes, null, false);

  const portMap = useMemo(() => {
    const map: Record<string, string> = {};

    for (const port of ports) {
      map[port.unlocode] = port.displayName;
    }

    return map;
  }, [ports]);

  const { data: commodities, isLoading: commoditiesLoading } = useAdminAPI<
    CommodityOption[]
  >(`${getBasePath()}/app-api/products/calculator-commodities`);

  const isApiLoaded = coreApiLoaded && !commoditiesLoading;

  const [calcFormSummaryNode, setSummaryNode] = useState<HTMLDivElement | null>(
    null
  );

  const [mobileSummaryNode, setMobileSummaryNode] =
    useState<HTMLElement | null>(null);

  useEffect(() => {
    const node = document.querySelector<HTMLElement>(mobileSummaryNodeSelector);

    if (node) {
      setMobileSummaryNode(node);
    }
  }, [mobileSummaryNodeSelector]);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);

  const setMinimized = (minimized: boolean) => {
    if (minimized) {
      if (isMobileSize()) {
        setIsMinimized(minimized);
      }
    } else {
      setIsMinimized(minimized);
    }
  };

  const [payload, setPayload] = useState<MultiModeInput | null>(null);

  const [sortModalOpen, setSortModalOpen] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const urlValues = useMemo(() => {
    return getInitialValuesFromParams(
      searchParams,
      isCFROptimizer
    ) as any as Partial<MultiModeInput>;
  }, [searchParams, isCFROptimizer]);

  return (
    <CalcContext.Provider value={contextValue}>
      <CFROptimizerContext.Provider value={cfrCTX}>
        <CalculatorCopyContextProvider>
          {isSubmitted ? (
            <div className="flex flex-row max-xl:hidden">
              <div className="flex-grow" ref={setSummaryNode} />

              {!contextValue.isCFROptimizer ? (
                <div className="flex items-center justify-center">
                  <CopyButton label />
                </div>
              ) : null}
            </div>
          ) : null}
          <div
            className={cn("flex items-center justify-center", formClassName)}
          >
            <MultiCalculatorForm
              terms={terms}
              commodities={commodities ?? []}
              ports={ports}
              summaryNode={calcFormSummaryNode}
              isLoading={!isApiLoaded}
              minimized={isMinimized}
              mobileSummaryNode={mobileSummaryNode}
              urlValues={urlValues}
              onSubmit={(data: MultiModeInput) => {
                setOptimizerStatus(null);

                const params = toSearchParams(data, isCFROptimizer);

                if (extraSearchParams) {
                  for (const [key, value] of Object.entries(
                    extraSearchParams
                  )) {
                    params.append(key, `${value}`);
                  }
                }

                router.push(`${baseRoute}?${params}`, navigateOptions);

                setPayload(data);
                setIsSubmitted(true);
                setMinimized(true);
              }}
              setSortModalOpen={setSortModalOpen}
              setFilterModalOpen={setFilterModalOpen}
            />
          </div>

          <div>
            {isSubmitted && (
              <div className="pt-8">
                <CalculationResults
                  hasToleranceParam={false}
                  payload={isSubmitted ? payload : null}
                  vesselMap={vesselMap}
                  sortModalOpen={sortModalOpen}
                  setSortModalOpen={setSortModalOpen}
                  filterModalOpen={filterModalOpen}
                  setFilterModalOpen={setFilterModalOpen}
                  optimizerStatus={optimizerStatus}
                  portMap={portMap}
                />
              </div>
            )}
          </div>
        </CalculatorCopyContextProvider>
      </CFROptimizerContext.Provider>
    </CalcContext.Provider>
  );
}
