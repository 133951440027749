export const convertCbftToHectoliterFormatted = (cbft: number) => {
  return Math.round(3531 / cbft);
};

export const convertHectoliterToCbftFormatted = (hectoliter: number) => {
  const cbft = (1 / hectoliter) * 3.531;
  return cbft.toFixed(0);
};

export const hectoLiterToCBFT = (hectoliter: number) => {
  const cbft = (1 / hectoliter) * 3.531;

  return cbft;
};

export const convertMToKgFormatted = (mt: number) => {
  return Number(mt * 1000).toFixed(0);
};
