import { useCallback, useMemo } from "react";
import { InputWrapper } from "./Inputs";

import { CommodityOption } from "@/actions/products/types";
import { Select, usePinning } from "@/ui-lib/select";
import { DBBackedPinAdapter } from "@/components/pin-adapter";

export default function CommoditySelector({
  commodities,
  isLoading,
  value,
  error,
  setValue,
  stowageFactor,
  setStowageFactor,
  color = "lightGrey",
}: {
  commodities: CommodityOption[];
  isLoading?: boolean;

  value: string;
  setValue: (value: string | null) => void;

  error?: string;

  color?: "lightGrey" | "grey";

  stowageFactor?: number;
  setStowageFactor?: (value: number) => void;
}) {
  // const pinning = usePinning("commodity", DBBackedPinAdapter);

  const onSetValue = useCallback(
    (newValue: string | null) => {
      const rawValue = `${newValue ?? ""}`.split(":::")[0];

      setValue(rawValue || null);

      if (newValue && setStowageFactor) {
        const stowage = `${newValue ?? ""}`.split(":::")[1];

        if (stowage) {
          setStowageFactor(stowage ? parseFloat(stowage) : 0);
        }
      }
    },
    [setValue, setStowageFactor]
  );

  const valuesWithStowage = useMemo(
    () =>
      commodities.map((option) => ({
        ...option,
        value: `${option.value}:::${option.stowageFactorCBFT}`,
      })),
    [commodities]
  );

  const stowageFallback = useMemo(() => {
    if (value) {
      const found = commodities.find((c) => c.value === value);

      if (found) {
        return found.stowageFactorCBFT;
      }
    }

    return null;
  }, [value, commodities]);

  const selectedValue = useMemo(() => {
    if (value) {
      if (stowageFactor) {
        return `${value}:::${stowageFactor}`;
      }

      if (stowageFallback) {
        return `${value}:::${stowageFallback}`;
      }

      return null;
    }

    return null;
  }, [value, stowageFactor, stowageFallback]);

  return (
    <InputWrapper id={"commodity"} isRequired label="Commodity" error={error}>
      <Select
        placeholder="Commodity"
        multiple={false}
        rounded
        color={color}
        mode="dark"
        loadingAnimation="pulse"
        options={valuesWithStowage}
        value={selectedValue ?? null}
        onChange={onSetValue}
        loading={isLoading}
        hasError={!!error}
        /*
        loading={isLoading || pinning.pending}
        pinEnabled
        pinOption={pinning.pinOption}
        pinnedOptions={pinning.pinnedOptions}
        */
      />
    </InputWrapper>
  );
}
