import * as z from "zod";

import { OperationType } from "@copmer/calculator-widget";
import {
  CalculatorModeString,
  EmptyStringToUndefined,
  NumberPreprocessor,
} from "./types";
import { generateTransformSchema } from "./zod-transform";

export interface MultiModeInput {
  mode: CalculatorModeString;

  ports: PortInput[];

  hire: number | undefined;
  sfoPrice: number | undefined;
  mgoPrice: number | undefined;

  useCache: any;

  // Only used for cfr optimizer
  optimizerMode?: "optimize" | "fixed";
  optimizeBase?: "cheapest" | "profitLoss";
  period?: string;
}

export interface PortInput {
  port: string | undefined;

  portDA: number | undefined;

  operations: PortOperationInput[];
}

export interface PortOperationInput {
  operation: OperationType;
  amount: number | undefined;
  commodity: string | undefined;

  cadence: number | undefined;
  terms: string | undefined;

  draft: number | undefined;

  tolerance: number | undefined;
  commodityPrice: number | undefined;
  userCfrPrice: number | undefined;

  stowageFactor: number | undefined;

  // Note: There is no input for these in the UI
  useVesselGear: undefined;
}

export const MultiPortOperationSchema = z.object({
  operation: z.enum([OperationType.L, OperationType.D]),

  amount: z.preprocess(
    NumberPreprocessor,
    z
      .number({
        required_error: "Quantity is required",
        invalid_type_error: "Quantity is required",
      })
      .min(1)
  ),
  commodity: z.preprocess(
    EmptyStringToUndefined,
    z.string({
      required_error: "Commodity is required",
      invalid_type_error: "Commodity is required",
    })
  ),

  cadence: z.preprocess(NumberPreprocessor, z.number().min(1).optional()),
  terms: z.preprocess(EmptyStringToUndefined, z.string().optional()),
  draft: z.preprocess(NumberPreprocessor, z.number().min(0.1).optional()),

  tolerance: z.preprocess(
    NumberPreprocessor,
    z.number().min(0).max(99).optional()
  ),

  stowageFactor: z.preprocess(NumberPreprocessor, z.number().min(0).optional()),

  commodityPrice: z.preprocess(
    NumberPreprocessor,
    z.number().min(0).optional()
  ),
  userCfrPrice: z.preprocess(NumberPreprocessor, z.number().min(0).optional()),
});

export const MultiPortSchema = z
  .object({
    port: z.preprocess(
      EmptyStringToUndefined,
      z.string({
        required_error: "Port is required",
        invalid_type_error: "Port is required",
      })
    ),

    portDA: z.preprocess(NumberPreprocessor, z.number().min(0).optional()),

    operations: z.array(MultiPortOperationSchema).min(1, {
      message: "At least one operation is required",
    }),
  })

  .superRefine((data, ctx) => {
    const memory = new Set<string>();

    // Check that all commodity/operation paris are unique
    for (const [idx, operation] of data.operations.entries()) {
      const key = `${operation.commodity}-${operation.operation}`;

      if (memory.has(key)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Commodity and operation pairs must be unique per port",
          path: ["operations", idx, "commodity"],
        });
        continue;
      }

      memory.add(key);
    }
  });

const MultiModeFormSchemaBase = z.object({
  mode: z
    .enum([CalculatorModeString.Simple, CalculatorModeString.Advanced])
    .default(CalculatorModeString.Simple),

  ports: z.array(MultiPortSchema).min(2, {
    message: "At least two ports are required",
  }),

  hire: z.preprocess(NumberPreprocessor, z.number().min(0).optional()),
  sfoPrice: z.preprocess(NumberPreprocessor, z.number().min(0).optional()),
  mgoPrice: z.preprocess(NumberPreprocessor, z.number().min(0).optional()),

  useCache: z.boolean().default(true),

  optimizerMode: z.enum(["optimize", "fixed"]).optional(),
  optimizeBase: z.enum(["cheapest", "profitLoss"]).optional(),

  period: z.string().optional(),
});

export const MultiModeFormSchema = MultiModeFormSchemaBase.superRefine(
  (data, ctx) => {
    // Check that all cargo unloaded in ports has been loaded in previous ports
    const cargoTracker = new Map<string, number>();

    for (const [index, port] of data.ports.entries()) {
      for (const [operationIndex, operation] of (
        port.operations ?? []
      ).entries()) {
        const key = `${operation.commodity}:${operation.stowageFactor}`;

        if (!key) {
          continue;
        }

        if (operation.operation === OperationType.L) {
          let target = cargoTracker.get(key) ?? 0;

          target += operation.amount ?? 0;

          cargoTracker.set(key, target);
        } else {
          const commodityKeys = Array.from(cargoTracker.keys()).filter((k) =>
            k.startsWith(operation.commodity)
          );

          let target = cargoTracker.get(key) ?? 0;

          target -= operation.amount ?? 0;

          cargoTracker.set(key, target);

          if (target < 0) {
            // Show a different error if only stowage factor mismatches

            if (commodityKeys.length > 0 && !commodityKeys.includes(key)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message:
                  "Unloading different stowage factor than available in ship.",
                path: [
                  "ports",
                  index,
                  "operations",
                  operationIndex,
                  "commodity",
                ],
                fatal: true,
              });
            } else {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Unloading more cargo than available in ship.",
                path: ["ports", index, "operations", operationIndex, "amount"],
                fatal: true,
              });
            }
          }

          cargoTracker.set(key, target);
        }
      }
    }
  }
)
  .superRefine((data, ctx) => {
    // If we are in cfr optimizer and optimizeBase is profitLoss, the FOB price must be set

    if (!data.optimizerMode || data.optimizeBase !== "profitLoss") {
      return;
    }

    for (const [index, port] of data.ports.entries()) {
      for (const [operationIndex, operation] of (
        port.operations ?? []
      ).entries()) {
        if (
          !operation.commodityPrice &&
          operation.operation === OperationType.L
        ) {
          // Add the error to highlight that the FOB price is required
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `FOB price is required in profit/loss mode.`,
            path: [
              "ports",
              index,
              "operations",
              operationIndex,
              "commodityPrice",
            ],
            fatal: true,
          });
        }
      }
    }
  })
  .superRefine((data, ctx) => {
    // Ensure that total loaded quantity exceeds 10k
    const totalLoaded = data.ports.reduce((acc, port) => {
      for (const operation of port.operations) {
        if (operation.operation === OperationType.L) {
          acc += operation.amount ?? 0;
        }
      }

      return acc;
    }, 0);

    if (totalLoaded < 10000) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          "Total loaded quantity is too small, enter a value above 10k, calculations on Coasters not possible because of lack of market data",
        path: ["ports", "root"],
      });
    }
  });

export const MultiModeTransformSchema = generateTransformSchema(
  MultiModeFormSchemaBase
);

export const defaultMultiFormValues = {
  mode: CalculatorModeString.Simple,
  ports: [
    {
      port: "",
      operations: [
        {
          operation: OperationType.L,
          commodity: "",
          amount: "",
          tolerance: 0,
          commodityPrice: 0,
          userCfrPrice: 0,
          stowageFactor: undefined,
        },
      ],
    },
    {
      port: "",
      operations: [
        {
          operation: OperationType.D,
          commodity: "",
          amount: "",
          tolerance: 0,
          commodityPrice: 0,
          userCfrPrice: 0,
          stowageFactor: undefined,
        },
      ],
    },
  ],
  useCache: true,
};
