import { createContext, Dispatch, SetStateAction, useContext } from "react";

interface CFROptimizerContextT {
  cfrOptimizerEnabled: boolean;

  isCFROptimizer: boolean;

  setIsCFROptimizer: Dispatch<SetStateAction<boolean>>;
}

export const CFROptimizerContext = createContext<CFROptimizerContextT>({
  cfrOptimizerEnabled: true,

  isCFROptimizer: false,
  setIsCFROptimizer: () => {},
});

export const useIsCFROptimizer = () => {
  return useContext(CFROptimizerContext);
};
